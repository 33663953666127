// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: 20px;
    background-color: dodgerblue;
}

.navbar-left {
    display: flex;
    justify-content: left;
}

.navbar-center {
    display: flex;
    justify-content: center;
}

.navbar-right {
    display: flex;
    justify-content: right;
}

.navbar-item {
    text-align: center;
    padding: 2em;
}

.navbar a {
    color: black;
    text-decoration: none;
}

.navbar a:hover {
    background-color: rgba(255, 0, 0, 0.75);
}`, "",{"version":3,"sources":["webpack://./src/css/navbar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,8BAA8B;IAC9B,eAAe;IACf,4BAA4B;AAChC;;AAEA;IACI,aAAa;IACb,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,qBAAqB;AACzB;;AAEA;IACI,uCAAuC;AAC3C","sourcesContent":[".navbar {\n    display: flex;\n    width: 100%;\n    justify-content: space-between;\n    font-size: 20px;\n    background-color: dodgerblue;\n}\n\n.navbar-left {\n    display: flex;\n    justify-content: left;\n}\n\n.navbar-center {\n    display: flex;\n    justify-content: center;\n}\n\n.navbar-right {\n    display: flex;\n    justify-content: right;\n}\n\n.navbar-item {\n    text-align: center;\n    padding: 2em;\n}\n\n.navbar a {\n    color: black;\n    text-decoration: none;\n}\n\n.navbar a:hover {\n    background-color: rgba(255, 0, 0, 0.75);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
